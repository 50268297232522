import React from "react";

import "./DraftDetail.scss";

const DraftDetail = () => (
  <div className="draft-detail">
    <span>Draft</span>
  </div>
);

export default DraftDetail;
