import React from "react";
import cx from "classnames";

import "./DetailList.scss";

const DetailList = ({ children, emphasized, vertical = false, noSeperator = false }) => (
    <div className={cx("detail-list", {
        "detail-list--emphasized": emphasized,
        "detail-list--vertical": vertical,
        "detail-list--no-seperator": noSeperator
    })}>
        {children.map((node, index) => (
            <React.Fragment key={index}>
                {node}
            </React.Fragment>
        ))}
    </div>
);

export default DetailList;
