import React from "react";

import "./IconDetail.scss";

const IconDetail = ({
    icon,
    link = null,
    children
}) => {
    const content = (<>
        {icon}
        <span>{children}</span>
    </>);

    return (
        link ?
        (<a className="icon-detail" href={link} target="_blank" rel="noreferrer noopener">{content}</a>) :
        (<span className="icon-detail">{content}</span>)
    );
};

export default IconDetail;
