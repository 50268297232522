import React from "react";

import BlogCategory from "../../components/BlogCategory/BlogCategory";

import "./BlogCategories.scss";

const BlogCategories = ({ categories }) => (
    <>
        {categories && (
            <ul className="blog-categories">
                {categories.map(category => (
                    <li key={category}><BlogCategory category={category}/></li>
                ))}
            </ul>
        )}
    </>
);

export default BlogCategories;
