import React from "react";
import moment from "moment";

import IconDetail from "../IconDetail/IconDetail";
import { ReactComponent as Calendar } from "../../svg/calendar.svg";

import "./TimeDetail.scss";

const TimeDetail = ({
    time
}) => (
    <IconDetail icon={<Calendar />}>
        <time dateTime={moment(time).toISOString()}>
            {moment(time).format(`MMMM D, YYYY`)}
        </time>
    </IconDetail>
);

export default TimeDetail;
