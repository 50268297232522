import React from "react";

import { stringToHue } from "../../utils/colors";

import "./BlogCategory.scss";

const BlogCategory = ({
    category,
    hue = stringToHue(category)
}) => (
    <span className="blog-category" style={{
        color: `hsl(${hue}, 40%, 10%)`,
        backgroundColor: `hsl(${hue}, 80%, 90%)`
    }}>{category}</span>
);

export default BlogCategory;
